// @flow
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import React, { useEffect, useState } from "react";
import { Link, useHistory, useLocation } from "react-router-dom";
import useFetch from "../shared_components/useFetch";
import PasswordAuthentication from "./PasswordAuthentication";
import SSOAuthentication from "./SSOAuthentication";
import Loader from "react-loader-spinner";
import { useSpring, animated } from "react-spring/web.cjs.js";
import type { IdentityProvider } from "../sso_setup/useIdentityProviders";

type UserInfo = {|
  identity_providers: Array<IdentityProvider>,
|};

type AuthenticationType = "password" | "sso";

type AuthenticationStepContentProps = {|
  email: string,
  userInfo: UserInfo,
|};

function AuthenticationStepContent({
  email,
  userInfo,
}: AuthenticationStepContentProps) {
  const style = useSpring({ opacity: 1, from: { opacity: 0 } });

  const [authTypeChoice, setAuthTypeChoice] =
    useState<AuthenticationType>("sso");

  const identityProviders = (userInfo && userInfo.identity_providers) || [];
  const hasSSO = identityProviders.length > 0;

  useEffect(() => {
    const noticeContainer = document.getElementById("notice-container");
    if (!noticeContainer) {
      throw new Error("no notice container");
    }
    noticeContainer.innerText = "";
  }, []);

  const history = useHistory();

  useEffect(() => {
    return () => {
      if (history.action === "POP") {
        window.localStorage.removeItem("lastLoginEmail");
      }
    };
  }, [history]);

  useEffect(() => {
    window.loginError = null;
  }, []);

  return (
    <animated.div style={style}>
      <h2 style={{ textAlign: "center", marginBottom: 20 }}>{email}</h2>
      {userInfo &&
        (authTypeChoice === "sso" && hasSSO ? (
          <SSOAuthentication
            email={email}
            toggleAuthenticationType={() => {
              setAuthTypeChoice("password");
            }}
            identityProviders={identityProviders}
          />
        ) : (
          <PasswordAuthentication
            email={email}
            toggleAuthenticationType={
              hasSSO
                ? () => {
                  setAuthTypeChoice("sso");
                }
                : null
            }
          />
        ))}
      <p className="forgot">
        <Link
          onClick={() => {
            window.localStorage.removeItem("lastLoginEmail");
          }}
          to="/"
        >
          Log in as a different user
        </Link>
      </p>
    </animated.div>
  );
}

export default function AuthenticationStep() {
  const location = useLocation();
  const email = new URLSearchParams(location.search).get("email") || "";

  const params = new URLSearchParams();
  params.append("email", email);
  const { fetched, data: userInfo }: { data: ?UserInfo, fetched: boolean } =
    useFetch(`/api/user_info?${params.toString()}`);

  if (!fetched) {
    return (
      <Loader
        style={{ textAlign: "center" }}
        type="Puff"
        color="#00BFFF"
        height={100}
        width={100}
        timeout={3000} // 3 secs
      />
    );
  }

  return userInfo ? (
    <AuthenticationStepContent email={email} userInfo={userInfo} />
  ) : null;
}
